import { Flex, Box, Text, Center, SimpleGrid, Card, CardHeader, Heading, CardBody, CardFooter, Button, Stat, StatLabel, StatNumber, StatHelpText, StatArrow, StatGroup, Avatar, Input, useToast, Tag} from '@chakra-ui/react'
import AXIOS from '../../Config/axiosConfig'
import { useEffect, useState } from 'react'
import {  SideBarMenuAdm, NavBarAdm, Loader } from '../../Components/index'
import { Link, useNavigate } from 'react-router-dom'
import CONFIG from '../../Config'
import { MdArrowBack, MdArrowForward, MdError, MdNotificationsActive } from 'react-icons/md'
import ReactPaginate from 'react-paginate'
import useSWR from 'swr'


function Notificacoes() {

  const [referencias, setReferencias] = useState([])
  const [referenciasFilter, setReferenciasFilter] = useState([])
  const navigate =  useNavigate()
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
  const [user, setUser] = useState('1')
  const [mes, setMes] = useState(new Date().toISOString().split("T")[0].substring(0,7))
  const [loader, setLoader] = useState(false)
  const toast = useToast()
  
  
  async function axiosReferencias(){
    
    const {mensagem:{empresa_contratante: empresa}} = JSON.parse(loginValue)
    const referencia = await AXIOS.get(`/transacoes/agrupado/entidades/somatorio-mensal/${mes}`, {headers: {empresa}})
    if(referencia.data.status === "erro")
        setReferencias([])
    else{
        setReferencias(referencia.data.mensagem)
        setReferenciasFilter(referencia.data.mensagem)
    }
        
    
    setLoader(true)
        
}

const fetcher = async (url) =>{
  const {mensagem:{empresa_contratante: empresa}} = JSON.parse(loginValue)
  const {data} = await AXIOS.get(url, {headers: {empresa}})
  
  return data
}

const { data, error, isLoading } = useSWR(`/transacoes/agrupado/entidades/somatorio-mensal/${mes}`, fetcher)

  async function clickAxiosReferencias(){
    
    const {mensagem:{empresa_contratante: empresa}} = JSON.parse(loginValue)
    const referencia = await AXIOS.get(`/transacoes/agrupado/entidades/somatorio-mensal/${mes}`, {headers: {empresa}})
    if(referencia.data.status === "erro")
        setReferencias([])
    else{
        setReferencias(referencia.data.mensagem)
        setReferenciasFilter(referencia.data.mensagem)
    }   
    setLoader(true)
        
}

async function gerarRelatorio(entidade){
  
    const res =  await AXIOS.post(`/transacoes/relatorio`,
        {
          "entidade":entidade,
          "campos": {tempo_transacao: mes}
      }) 

        toast({
          title: 'Gerar relatorio',
          description: "Á carregar o registro",
          status: 'loading',
          duration: 3000,
          isClosable: true,
        })
        
        if(res.data.status === "sucesso"){
          
          setTimeout(() => {
            window.open(`${CONFIG.HOST_API}/reports/`+res.data.mensagem.filename)
          }, 3000)
          
          setTimeout(()=>{
            toast({
              title: 'Gerar relatorio',
              description: "Gerado com sucesso",
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
          },3500)
        }else{
          setTimeout(()=>{
            toast({
              title: 'Gerar relatorio',
              description: "Aconteceu, um problema",
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          },5000)
        }
}
  useEffect(()=>{
     
    axiosReferencias(); 
    const value = JSON.parse(loginValue)
    if(value?.mensagem?.tipo_usuario){
     setUser(value.mensagem)
    }else{
        navigate('/login')
      }
      
  },[])
  
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 20
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  let permissao = user?.pagina_custo_uso == "true";

  if(user?.pagina_custo_uso) permissao = user?.pagina_custo_uso == "true"
  else permissao = user.todas_areas ? true : false;

  if (isLoading){ 
    return <Flex color='white' bg="white" direction="row" style={{minHeight:'100vh'}}>
            <Center mt={10}> 
              <Loader /> 
            </Center>
          </Flex>
  }
  
return (
  <>
  <Flex color='white' bg="gray.300" direction="row">
    <SideBarMenuAdm />
    <Box flex={1}>
      <NavBarAdm/>
        {
          
          permissao

          ? 
          
          <Box px={10} color="black" mt={10}>
          <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={10}>Custo por uso das entidades</Text>
        {
        loader
        ?
        <Box shadow="sm" rounded={5} bg="white" p={10} >
          <ReactPaginate
                     breakLabel="..."
                     nextLabel={<MdArrowForward/>}
                     onPageChange={handlePageClick}
                     pageRangeDisplayed={5}
                     pageCount={pageCount}
                     breakClassName="list-group-item"
                     previousLabel={<MdArrowBack/>}
                     renderOnZeroPageCount={null}
                      />
          <Input mt={3} name="data" width="xs" type='month' onChange={(evt) => setMes(evt.target.value)} />
          <Button ml={2} colorScheme='orange' onClick={clickAxiosReferencias}>Buscar</Button>
          <SimpleGrid mt={5} spacing={4} templateColumns='repeat(auto-fill, minmax(25%, 1fr))'>
            {currentItems.map(el => 
            <Card>
              <CardHeader>
                <Heading size='md'><Tag>{mes}</Tag></Heading>
                <Flex mt={3} flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                  <Avatar name={el.responsavel} src={`${CONFIG.HOST_API}/images/${el.logo}`} />
                  <Box>
                    <Heading size='sm'><Link to={`/adm/custo-por-uso/${el.numero_entidade}`}>{el.nome_empresa}</Link></Heading>
                    <Text>{el.responsavel.substring(0,20)} | <span style={{color:"red"}}>{el.tipo_cliente}</span> | <span style={{color:"red"}}>{el.forma_de_uso}</span></Text>
                    <Text fontWeight={500} fontSize={14}>Contrato <b>{new Date(el.criando_em).toLocaleDateString()}</b> Até <b>{new Date(el.termino_contrato).toLocaleDateString()}</b></Text>
                  </Box>
                  <Flex justifySelf="flex-end" >
                    {(el.dias_restantes < 30 ) && <MdNotificationsActive className='animate__animated animate__bounce animate__infinite	infinite' size={40} color='#f04e72' />}
                    {(el.dias_restantes < 30 ) &&  <b style={{fontSize:13}}>{el.dias_restantes}</b>}
                  </Flex>
                </Flex>
              </CardHeader>
              <CardBody>
                <Text>Valor à cobrar</Text>
                <Heading color="green.400" fontWeight={900} size="xl">AKZ {Intl.NumberFormat('PT-br').format(el.registo.valor_fixo)}</Heading>
                <Text fontSize={14}>Contablidade</Text>
                <Heading color="green.400" fontWeight={600} size="md">AKZ {Intl.NumberFormat('PT-br').format(el.registo.somaTotal)}</Heading>
                <StatGroup mt={5}>
                  <Stat>
                    <StatLabel>Montante</StatLabel>
                    <StatNumber fontSize={17} fontWeight={600}>AKZ {Intl.NumberFormat('PT-br').format(el.registo.montante)}</StatNumber>
                    <StatHelpText>
                      <StatArrow type='increase' />
                      {Intl.NumberFormat('PT-br').format(el.percentual == 1 ? 0 : el.percentual)}%
                    </StatHelpText>
                  </Stat>

                  <Stat>
                    <StatLabel>Quantidade</StatLabel>
                    <StatNumber fontSize={17} fontWeight={600}>{Intl.NumberFormat('PT-br').format(el.registo.quantidade)}</StatNumber>
                    {/* <StatHelpText>
                      <StatArrow type='decrease' />
                      9.05%
                    </StatHelpText> */}
                  </Stat>
                </StatGroup>
              </CardBody>
              <CardFooter display="flex" justifyContent="space-between" flexWrap="wrap">
                <Button colorScheme='facebook' as={Link} to={`/adm/referencias/${el.numero_entidade}`}>Referências</Button>
                <Button colorScheme='facebook' as={Link} to={`/adm/pagamentos/${el.numero_entidade}`} ml={3}>Pagamentos</Button>
                <Button colorScheme='facebook' as={Link} to={`/adm/transacoes/${el.numero_entidade}`} ml={3}>Transações</Button>
                <Button onClick={() => gerarRelatorio(el.numero_entidade)} width="full" mt={3} colorScheme='orange'>Relatório</Button>
              </CardFooter>
            </Card>
          )}
          </SimpleGrid>
        </Box>
        :
        <Center>
          <Loader/>
        </Center>}
          </Box>

          :
          
          <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
            <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
            <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
            <MdError color='red' size={200} />
          </Box>
        }
    </Box>
  </Flex>
</>
)
}

export default Notificacoes