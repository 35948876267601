
const config =  {
    // HOST_API: "http://10.10.40.53:8443/v1",
    // HOST_API: "https://10.10.40.53:1144/v1",
    // HOST_API: "https://10.10.40.10:1144/v1",
    // HOST_API: "https://appay.intelize.ao:9953/v1",
    // HOST_API: "https://appay.intelize.ao:1000/v1",
    // HOST_API: "https://appay.intelize.ao:5000/v1",
    // HOST_API: "https://api.intelize.ao/v1",
    HOST_API: "https://api.intelize.digital/v1",
    // HOST_API: "https://api-teste.intelize.ao/v1",
    // HOST_API: "https://10.10.40.40:8443/v1", 
    // HOST_API: "https://127.0.0.1:8443/v1",
    // HOST_API: "http://127.0.0.1:7443/v1", 
    // HOST_API: "https://127.0.0.1:9553/v1", 
    // HOST_API: "https://127.0.0.1:1144/v1", 
    // HOST_API: "http://10.10.10.30:7443/v1", 
    // HOST_API: "https://196.61.78.42:9953/v1", 
    // HOST_API: "https://10.10.20.2:9553/v1", 
    COOKIE_SESSION: {
        CLIENT:"login$PORTALINTELIZE2023#_28fc113b2d8229071bec2afa9e653157=",
        ADM:"login$PORTALINTELIZE2023#_c2331389c53b6d52f1e5344590412795=",
        SECURITY_CODE_ENTITY: "security$Intelize#@_skj87sjsjskkk#kkk=",
        ID_CODE_ENTITY: "security$Intelize_payment#@_skj87sjsjs0098Hkkk#kkk=",
        COOKIE: "cookie$Intelize_payment=",
    },
    COMPANY:{
        NAME:"INTELIZE INVESTIMENTOS",
        CONTACTS:[],
        EMAILS:["suporte@intelize.ao", "comercial@intelize.ao", "projectos@intelize.ao"],
        ADDRESS: "Luanda, Talatona, Atlântico Business, Escritorio nº 28" 
    }
} 
export default config;