import {Flex,
  Box,
  Text,
  Heading,
  Center,
  useToast,
  Button,
  Input} from '@chakra-ui/react'
import AXIOS from '../../Config/axiosConfig'
import { useEffect, useRef, useState } from 'react'
import { ModalViewPagamento, SideBarMenu, NavBar, Loader, SideBarMenuAdm, NavBarAdm, BotaoTop, DrawerFiltrosTransacoes } from '../../Components/index'
import { useNavigate, useParams } from 'react-router-dom'
import { MdCached, MdCopyAll, MdError, MdUpdate } from "react-icons/md";
import CONFIG from '../../Config'
import { useTranslation } from 'react-i18next'


function Transacoes() {

    const [referencias, setReferencias] = useState([])
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const {id} = useParams();
    const {t, i18n} = useTranslation()
    const [user, setUser] = useState("1");
    const [secretKey, setSecretKey] = useState("");
    const toast = useToast();
    const refSecret = useRef();
    const [loader, setLoader] = useState(false);
  
    async function axiosReferencias() {
      try {
        const entidade = id
        const referencia = await AXIOS.get(
          `/credencial-clientes/entidade/${entidade}`
        );
        if (referencia.data.status === "erro") setReferencias([]);
        else {
          if (referencia.data.mensagem.length) {
            setReferencias(referencia.data.mensagem[0]);
            setSecretKey(referencia.data.mensagem[0].secret_api);
          } else {
            setReferencias([]);
          }
        }
        
        setLoader(true);
        
      } catch (error) {
        navigate("/sem-comunicacao");
      }
    }
  
    const copiarToken = (value) => {
      try {
        navigator.clipboard.writeText(value);
        toast({
          title: t("Token"),
          description: t("Token copiado com sucesso"),
          status: "success",
          duration: 3000,
          isClosable: true
        });
      } catch (error) {
        toast({
          title: t("Alerta"),
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: "error"
        });
      }
    };
  
    const gerarToken = async () => {
      try {
        const dados = {
          entidade: id, 
          secret_api: secretKey
        };
  
        const res = await AXIOS.post(
          `/credencial-clientes`,
          dados
        );
        console.log(res.data);
        if (res.data.status == "sucesso") {
          setReferencias({ token_api: res.data.mensagem.token });
          toast({
            title: t("Token gerado"),
            description: t("Token gerado com sucesso"),
            status: "success",
            duration: 3000,
            isClosable: true
          });
          axiosReferencias();
        } else {
          toast({
            title: t("Token gerado"),
            description: t(res.data.mensagem),
            status: "error",
            duration: 3000,
            isClosable: true
          });
        }
      } catch (error) {
        toast({
          title: t("Alerta"),
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: "error"
        });
      }
    };
  
    const gerarSecretKey = () => {
      const secret = btoa(Math.random() + "" + Math.random());
      refSecret.current.value = secret;
      setSecretKey(secret);
    };

    useEffect(()=>{
      axiosReferencias()
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.id_usuarios){
       setUser(value.mensagem)
      }else{
            navigate('/login')
        }
    },[])
            

let permissao = user?.pagina_servico_api == "true";

if(user?.pagina_servico_api) permissao = user?.pagina_servico_api == "true"
else permissao = user.todas_areas ? true : false;
    
  return (
  <>
    
    <Flex color='white' bg="gray.300" direction="row">
      <SideBarMenuAdm />
      <BotaoTop/>
      <Box flex={1}>
        <NavBarAdm/>
        
        {
          permissao

          ?
        <Flex direction="column" p={10} style={{minHeight:'90vh'}}>
          <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={10}>Token API</Text>
            {loader ? (
                <Flex color="black" bg="white" rounded={5} p={10} boxShadow="md">
                  <Flex
                    direction="column"
                    justifyContent="space-between"
                    style={{ width: "100vh" }}
                  >
                    <Heading color="gray.600" fontSize={20} mb={2}>
                      {t("Token API")}
                    </Heading>
                    <Box
                      style={{ width: 700 }}
                      border="0.4px solid #eee"
                      p={3}
                      rounded={10}
                    >
                      {referencias.token_api}
                    </Box>
                    <Flex>
                      <Button
                        mr={2}
                        bg="blue.400"
                        _hover={{ backgroundColor: "blue.600" }}
                        onClick={gerarToken}
                        leftIcon={<MdCached />}
                        mt={2}
                        color="white"
                      >
                        {t("Gerar")}
                      </Button>
                      {referencias.token_api && (
                        <Button
                          leftIcon={<MdCopyAll />}
                          onClick={() => copiarToken(referencias.token_api)}
                          mt={2}
                          bg="green.500"
                          color="white"
                          w={100}
                        >
                          {t("Copiar")}
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                  <Flex direction="column">
                    <Heading color="gray.600" fontSize={20} mb={2}>
                      {t("Chave de segredo")}
                    </Heading>
                    <Input
                      onChange={(evt) => setSecretKey(evt.target.value)}
                      type="text"
                      name="secretKey"
                      value={secretKey}
                      minLength={25}
                      ref={refSecret}
                      width={400}
                    />
                    <Button
                      bg="blue.400"
                      _hover={{ backgroundColor: "blue.600" }}
                      color="white"
                      onClick={gerarSecretKey}
                      leftIcon={<MdUpdate />}
                      mt={2}
                    >
                      {t("Gerar")}
                    </Button>
                  </Flex>
                </Flex>
              ) : (
                <Center>
                  {/* <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  /> */}
                  <Loader />
                </Center>
              )}
          
        </Flex>
        :   

        <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
          <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
          <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
          <MdError color='red' size={200} />
        </Box>
        }
      </Box>
    </Flex>
  </>
  )
}

export default Transacoes