import { Flex, Box, Text, Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer, Center, Input, useToast, Button, Select,NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,} from '@chakra-ui/react'
import AXIOS from '../../Config/axiosConfig'
import { useEffect, useState } from 'react'
import { ModalViewPagamento, SideBarMenu, NavBar, Loader, SideBarMenuAdm, NavBarAdm, BotaoTop, DrawerFiltrosTransacoes } from '../../Components/index'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { MdArrowBack, MdArrowForward, MdDoneAll, MdError, MdOutlineRemoveDone, MdUpgrade} from "react-icons/md"
import ReactPaginate from 'react-paginate';
import CONFIG from '../../Config'
import { useTranslation } from 'react-i18next'


function Transacoes() {

    const [referencias, setReferencias] = useState([])
    const [referenciasFilter, setReferenciasFilter] = useState([])
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const [user, setUser] = useState('1')
    const [loader, setLoader] = useState(false)
    const toast = useToast()
    const {id} = useParams();
    const {t, i18n} = useTranslation()
    
    async function axiosReferencias(){
    
    try {
      setLoader(false)
      const entidade = id
      const referencia = await AXIOS.get(`/transacoes/agrupado/entidades/somatorio/${entidade}/mensalidades`)

      console.log(referencia)
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
          setReferencias(referencia.data.mensagem)
          setReferenciasFilter(referencia.data.mensagem) 
        }
        
        
        setLoader(true)
          
      }catch (error) {
        toast({
          title: 'Alerta',
          description: "Não conseguimos ter comunicação! Verifique sua internet",
          status: 'error',
        })
    }
  }
  

    useEffect(()=>{
              axiosReferencias(); 
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.id_usuarios){
       setUser(value.mensagem)
      }else{
            navigate('/login')
          }
    },[])
    
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
  
    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const itemsPerPage = 50
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = referenciasFilter.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);
  
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

const meses = {
  "01": "Janeiro",
  "02": "Fevereiro",
  "03": "Março",
  "04": "Abril",
  "05": "Maio",
  "06": "Junho",
  "07": "Julho",
  "08": "Agosto",
  "09": "Setembro",
  "10": "Outubro",
  "11": "Novembro",
  "12": "Dezembro",
}
    
async function gerarRelatorio(entidade, mes){
  
  const res =  await AXIOS.post(`/transacoes/relatorio`,
      {
        "entidade":entidade,
        "campos": {tempo_transacao: mes}
    }) 

      toast({
        title: 'Gerar relatorio',
        description: "Á carregar o registro",
        status: 'loading',
        duration: 3000,
        isClosable: true,
      })
      
      if(res.data.status === "sucesso"){
        
        setTimeout(() => {
          window.open(`${CONFIG.HOST_API}/reports/`+res.data.mensagem.filename)
        }, 3000)
        
        setTimeout(()=>{
          toast({
            title: 'Gerar relatorio',
            description: "Gerado com sucesso",
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        },3500)
      }else{
        setTimeout(()=>{
          toast({
            title: 'Gerar relatorio',
            description: "Aconteceu, um problema",
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        },5000)
      }
}


let permissao = user?.pagina_transacoes == "true";

if(user?.pagina_transacoes) permissao = user?.pagina_transacoes == "true"
else permissao = user.todas_areas ? true : false;
    
  return (
  <>
    
    <Flex color='white' bg="gray.300" direction="row">
      <SideBarMenuAdm />
      <BotaoTop/>
      <Box flex={1}>
        <NavBarAdm/>
        {
          permissao

          ?
        <Flex direction="column" justifyContent="space-between" style={{minHeight:'90vh'}}>
            <Box px={10}>
              <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mt={5} mb={10}>Mensalidades de custo por uso {id}</Text>

              
            {loader ?
            <TableContainer mt={5} bg="white">
              <Table variant='striped' colorScheme='gray'>
                <TableCaption>
                  <ReactPaginate
                      breakLabel="..."
                      nextLabel={<MdArrowForward/>}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      breakClassName="list-group-item"
                      previousLabel={<MdArrowBack/>}
                      renderOnZeroPageCount={null}
                        />
                </TableCaption>
                <Thead p={10}>
                  <Tr bg="gray.600">
                    <Th py={6} color="gray.300">Quantidade de pagamentos</Th>
                    <Th py={6} color="gray.300">Contablidade</Th>
                    <Th py={6} color="gray.300">Mês</Th>
                    {/* <Th py={6} color="gray.300">Hora</Th> */}
                    {/* <Th py={6} color="gray.300">Produto</Th> */}
                    <Th py={6} color="gray.300">Percentual Actual</Th>
                    <Th py={6} color="gray.300">Montante transacionado</Th>
                    <Th py={6} color="gray.300">Valor à cobrar</Th>
                    <Th py={6} color="gray.300">Acção</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentItems.map(el =>
                      <Tr color="blackAlpha.700">
                        <Td>{el.registo.quantidade}</Td>
                        <Td fontWeight={700}>AKZ {Intl.NumberFormat('PT-br').format(el.registo.somaTotal)}</Td>
                        <Td>{new Date(el.registo.mensal).toLocaleDateString().toString().substr(-7)} - <b>{meses[new Date(el.registo.mensal).toLocaleDateString().toString().substr(-7).split('/')[0]]}</b></Td>
                        <Td>{Number(el.registo.percentual) < 1 ? Number(el.registo.percentual) * 100 : Number(el.registo.percentual)}</Td>
                        <Td fontWeight={700}>AKZ {Intl.NumberFormat('PT-br').format(el.registo.montante)}</Td>
                        <Td color="green.400" fontWeight={900}>AKZ {Intl.NumberFormat('PT-br').format(el.registo.valor_fixo)}</Td>
                        <Td>
                          <Button colorScheme='blackAlpha' as={Link} to={`/adm/referencias/${el.numero_entidade}`}>Refs.</Button>
                          <Button colorScheme='blackAlpha' as={Link} to={`/adm/transacoes/${el.numero_entidade}`} ml={3}>Trans.</Button>
                          <Button colorScheme='blackAlpha' onClick={() => gerarRelatorio(el.numero_entidade, el.registo.mensal )} ml={3}>Relatório</Button>
                        </Td>
                      </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            :
            <Center>
              {/* <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              /> */}
              <Loader />
            </Center>}
          </Box>
          <Box mt={5} color="gray.700" textAlign="center">
                    <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
                    <Text>Todos direitos reservados &copy; {new Date().getFullYear()}</Text>
          </Box>
        </Flex>
        :   

        <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
          <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
          <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
          <MdError color='red' size={200} />
        </Box>
        }
      </Box>
    </Flex>
  </>
  )
}

export default Transacoes