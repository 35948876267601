import { Flex, Box, Text, Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer, Center, Input, useToast, Button,
  Heading,NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select} from '@chakra-ui/react'
import AXIOS from '../Config/axiosConfig'
import { useEffect, useState } from 'react'
import { ModalViewPagamento, SideBarMenu, NavBar, Loader, BotaoTop } from '../Components/index'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { MdArrowBack, MdArrowForward, MdDone, MdDoneAll, MdError, MdOutlineRemoveDone, MdUpgrade} from "react-icons/md"
import ReactPaginate from 'react-paginate';
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import { TbPigMoney } from 'react-icons/tb'
import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'


function Pagamentos() {

  const [referencias, setReferencias] = useState([])
  const [referenciasQuantidade, setReferenciasQuantidade] = useState(0)
  const [referenciasAux, setReferenciasAux] = useState([])
  const [referenciasFilter, setReferenciasFilter] = useState([])
  const navigate =  useNavigate()
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
  const [user, setUser] = useState('1')
  const [loader, setLoader] = useState(false)
  const toast = useToast()
  const {periodo, data} = useParams()
  const {t, i18n} = useTranslation()
  const [pagina, setPagina] = useState('1')
  const [paginas, setPaginas] = useState('1')
  const [quantidade, setQuantidade] = useState('100')
  
  async function axiosReferencias(){
  
  try {
    const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
    const referenciaPagamentos = await AXIOS.get(`/pagamentos/entidade/${entidade}/periodo/${periodo}/data/${data}/?limite=${quantidade}&pagina=${pagina}`)
    
    if(referenciaPagamentos.data.status === "erro")
        setReferencias([])
    else{
        setReferencias(referenciaPagamentos.data.mensagem.pagamentos)
        setReferenciasQuantidade(referenciaPagamentos.data.mensagem.pagamentosQuantidade)
        setReferenciasFilter(referenciaPagamentos.data.mensagem.pagamentos)
        setReferenciasAux(referenciaPagamentos.data.mensagem)   
        setPagina(referenciaPagamentos.data.registros.pagina_actual) 
        setPaginas(referenciaPagamentos.data.registros.paginas) 
        setQuantidade(referenciaPagamentos.data.registros.limite) 
      }
      
      setLoader(true)
        
    }catch (error) {
      toast({
        title: t('Alerta'),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: 'error',
      })
  }
}


  useEffect(()=>{
            axiosReferencias(); 
    const value = JSON.parse(loginValue)
    if(value?.mensagem?.numero_entidade){
     setUser(value.mensagem)
    }else{
          navigate('/login')
        }
  },[])
  
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 50
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  
  const selectdItem = (evt) =>{
    const tipo = evt.target.value.toLocaleLowerCase()
    const modified = referencias.map(item => {
      
      return {
        ...item,
        "num_referencia": item.num_referencia,
        "referencia_do_montante": item.referencia_do_montante,
        "data_limite_pagamento": item.data_limite_pagamento,
        "data_inicio_de_pagamento": item.data_inicio_de_pagamento,
        "codigo_de_processamento": item.codigo_de_processamento == 80 ? "Activo" : "Inactivo" ,
        "registo_produto": item.registo_produto
      }
      
    
    })
    const filtered = modified.filter(item => JSON.stringify(item).toLocaleLowerCase().replaceAll('T', ' ').replaceAll('Z', '').replaceAll('-','/').indexOf(tipo) > -1)
    setReferenciasFilter(filtered)
    
}

const actualizarPagamentos = async () => {
try {
  setLoader(false)
  const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
  const referencia = await AXIOS.get(`/pagamentos/entidade/${entidade}/periodo/${periodo}/data/${data}/?limite=${quantidade}&pagina=${pagina}`)
  if(referencia.data.status === "erro")
      setReferencias([])
  else{
      setReferencias(referencia.data.mensagem.pagamentos)
      setReferenciasQuantidade(referencia.data.mensagem.pagamentosQuantidade)
      setReferenciasFilter(referencia.data.mensagem.pagamentos)  
      setReferenciasAux(referencia.data.mensagem)  
      setPagina(referencia.data.registros.pagina_actual) 
      setPaginas(referencia.data.registros.paginas) 
      setQuantidade(referencia.data.registros.limite) 
    }
    
    setLoader(true)
      
  }catch (error) {
    toast({
      title: t('Alerta'),
      description: t("Não conseguimos ter comunicação! Verifique sua internet"),
      status: 'error',
    })
}
};


const buscarComPaginacao  = async function(){
  try {
    setLoader(false)
    const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
    const referencia = await AXIOS.get(`/pagamentos/entidade/${entidade}/periodo/${periodo}/data/${data}/?limite=${quantidade}&pagina=${pagina}`)
    console.log(pagina)
    if(referencia.data.status === "erro")
      setReferencias([])
    else{
      setReferencias(referencia.data.mensagem.pagamentos)
      setReferenciasQuantidade(referencia.data.mensagem.pagamentosQuantidade)
      setReferenciasFilter(referencia.data.mensagem.pagamentos)  
      setReferenciasAux(referencia.data.mensagem)  
      setPagina(referencia.data.registros.pagina_actual) 
      setPaginas(referencia.data.registros.paginas) 
      setQuantidade(referencia.data.registros.limite) 
    }
    
      setLoader(true)
        
    }catch (error) {
      toast({
        title: t('Alerta'),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: 'error',
      })
  }
}
  
let permissao = user?.pagina_contabilistico == "true";

if(user?.pagina_contabilistico) permissao = user?.pagina_contabilistico == "true"
else permissao = user.numero_entidade

return (
<>
  
  <Flex color='white' bg="white" direction="row">
    <SideBarMenu />
    <BotaoTop/>
    <Box flex={1}>
    <NavBar titulo={t("Meus pagamentos")} imagem={<TbPigMoney size={300}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"}/>
    <Flex direction="column" justifyContent="space-between" style={{minHeight:'90vh'}}>
    {permissao? <Box px={10}>
          {/* <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={10}>{t("Meus pagamentos")}</Text> */}
          <Flex alignItems="center" mt={5} justifyContent="space-between">
            <Flex color="gray.500" alignItems="center">
              <Text></Text> <Input w="sm" bg="white" type="search" placeholder={`${t('Pesquise')} ...`} onChange={selectdItem}/>
              <Button
                  bg="blue.400"
                  _hover={{ backgroundColor: "blue.600" }}
                  ml={1}
                  onClick={actualizarPagamentos}
                  leftIcon={<MdUpgrade />}
                  color="white"
                >
                  {t("Carregar")}
                </Button>
            </Flex>

            <Flex color="gray.500" alignItems="center">
                <NumberInput
                name='pagina'
                onChange={(valueString) => setPagina(parseInt(valueString))}
                value={pagina}
                max={paginas}
                min={1}
                width={120}
                ml={1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Select ml={1} width={200} name='quantidade' onChange={e => setQuantidade(e.target.value)} value={quantidade}>
                  <option value="100">100</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                  <option value="2000">2000</option>
                  <option value="5000">5000</option>
                  <option value="10000">10000</option>
              </Select>
              <Button ml={1} onClick={buscarComPaginacao} colorScheme='orange'>Buscar</Button>
              </Flex>
          </Flex>
          
        {loader ?
        <Flex>
            <Box mt={5}>
              <Card align='center'>
                <CardHeader>
                  <Heading size='lg'> Pagamentos </Heading>
                </CardHeader>
                <CardBody width="100%" textAlign="left">
                  <Text textAlign="left">Nº Log EGR: <span style={{"font-weight":"700"}}>{periodo}</span></Text>
                  <Text textAlign="left">Data do periodo: <span style={{"font-weight":"700"}}>{new Date(data).toLocaleDateString()}</span></Text>
                  <Text textAlign="left">Quantidade: <span style={{"font-weight":"700"}}>{referenciasQuantidade}</span></Text>
                  <Text textAlign="left">Montante: <span style={{"font-weight":"700"}}> AKZ {Intl.NumberFormat("pt-BR").format(referenciasAux.montante.somatorio)}</span></Text>
                </CardBody>
                <CardFooter>
                  <Button onClick={()=> navigate('/pagamentos')} colorScheme='blue'>Todos pagamentos</Button>
                </CardFooter>
              </Card>

              <Card mt={5} align='center'>
                <CardHeader>
                  <Heading size='lg'> Referências</Heading>
                </CardHeader>
                <CardBody width="100%" textAlign="left">
                  <Text textAlign="left">Participantes: <span style={{"font-weight":"700"}}>{referenciasAux.referencias.length}</span></Text>
                </CardBody>
                <CardFooter>
                  <Button onClick={()=> navigate('/pagamentos')} colorScheme='blue'>Todas referências</Button>
                </CardFooter>
              </Card>
            </Box>
            <TableContainer flex={1} ml={5} mt={5} bg="white">
              <Table variant='striped' colorScheme='gray' boxShadow="md">
                <TableCaption>
                  <ReactPaginate
                      breakLabel="..."
                      nextLabel={<MdArrowForward/>}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      breakClassName="list-group-item"
                      previousLabel={<MdArrowBack/>}
                      renderOnZeroPageCount={null}
                        />
                </TableCaption>
                <Thead p={10}>
                  <Tr bg="gray.600">
                    <Th py={6} color="gray.300">{t("Referência")}</Th>
                    <Th py={6} color="gray.300">{t("Montante")}</Th>
                    <Th py={6} color="gray.300">{t("Movimentos")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentItems.map(el =>
                      <Tr color="blackAlpha.700">
                        <Td onClick={() => window.location.href = `/pagamentos/${el.referencia_do_servico}`} fontWeight={700} _hover={{textDecoration:'underline', cursor:"pointer", color:"#3294ed"}}>{el.referencia_do_servico}</Td>
                        <Td color="green.600" fontWeight={700}>AKZ {Intl.NumberFormat('PT-br').format(el.somatorio)}</Td>
                        <Td> 
                            {el.quantidade}
                        </Td>
                      </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
        </Flex>
        :
        <Center>
          {/* <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          /> */}
          <Loader />
        </Center>}
      </Box>
      :<Center width="100%">
      <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
          <MdError size={200} color="red"/>
          <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
          <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
      </Flex>
    </Center>}
      <Box mt={5} color="gray.700" textAlign="center">
                <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
                <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
      </Box>
    </Flex>
    </Box>
  </Flex>
</>
)
}

export default Pagamentos