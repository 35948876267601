import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { AdmDashboard, AdmEditarEntidades, AdmLogin, Afiliados, AfiliadosAdm, AfiliadosIdentidicadosGrupo, AfiliadosIndentificacao, ConfEntidadeServicos, ConfIntegracao, ConfPagamentos, ConfSMS, ConfServicos, ConfSessao, CustoPorUsoEntidades, CustoUso, Dashboard, DashboardEntidades, Definicoes, DefinirPermissoesAfiliados, Docs, EditarAfiliado, EditarAfiliadosAdm, EditarEndpoint, EditarEndpointAdm, EditarPermissao, EditarReferencias, EditarReferenciasAdm, EditarUsuarios, EmailMarket, ErroComunicacao, FAQ, Ficheiros, FicheirosFMOVs, Login, LoginAfiliado, Logs, LogsRequisicao, MinhaConta, MudarSenha, MudarSenhaPadrao, Notificacoes, POSComerciantes, Pagamentos, PagamentosEntidades, PagamentosEntidadesNaoProcessados, PagamentosGrupoAfiliados, PagamentosNaoProcessados, PagamentosReferenciado, Pagina404, Pagina404ADM, Percentual, PerdiMinhaSenha, PeriodoContabiblistico, PeriodoContabiblisticoEntidades, PeriodoContabiblisticoEntidadess, PeriodoContabiblisticoPagamentos, PeriodoContabiblisticoPagamentosEntidades, Permissoes, PoliticaDePrivacidade, RedifinirSenha, Referencias, ReferenciasEntidades, ReferenciasGrupoAfiliados, Relatorios, RelatoriosEntidades, SMS, SMSMarket, ServicoAPI, TermosECondicoes, TokenAPI, TokensEntidades, TransacoesEntidades, TransacoesEntidadesGPO, TransacoesGPO, TrocarSenhaSeguranca, Usuarios, Webhooks, WebhooksAdm, WebhooksLogs, WebhooksLogsAdm, WebhooksLogsAdmEntidade, WebhooksLogsEntidadeFalhas, WebhooksRetry, WhatsappMarket } from '../Pages'
import Entidades from "../Pages/Adm/Entidades";
  
const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard/>,
    },
    {
      path: "/referencias",
      element: <Referencias/>,
    },
    {
      path: "/referencias/grupo-afiliados/:grupo",
      element: <ReferenciasGrupoAfiliados/>,
    },
    {
      path: "/referencias/:id",
      element: <EditarReferencias/>,
    },
    {
      path: "/pagamentos",
      element: <Pagamentos/>,
    },
    {
      path: "/transacoes-online",
      element: <TransacoesGPO/>,
    },
    {
      path: "/pagamentos-nao-processado",
      element: <PagamentosNaoProcessados/>,
    },
    {
      path: "/pagamentos/:referencia",
      element: <PagamentosReferenciado/>,
    },
    {
      path: "/pagamentos/grupo-afiliados/:grupo",
      element: <PagamentosGrupoAfiliados/>,
    },
    {
      path: "/relatorios",
      element: <Relatorios/>,
    },
    {
      path: "/minha-conta",
      element: <MinhaConta/>,
    },
    {
      path: "/sms",
      element: <SMS/>,
    },
    {
      path: "/tokens",
      element: <TokenAPI/>,
    },
    {
      path: "/tokens",
      element: <TokenAPI/>,
    },
    {
      path: "/termos-e-condicoes",
      element: <TermosECondicoes/>,
    },
    {
      path: "/politica-de-privacidade",
      element: <PoliticaDePrivacidade/>,
    },
    {
      path: "/periodo-contabilistico",
      element: <PeriodoContabiblistico/>,
    },
    {
      path: "/periodo-contabilistico/periodo/:periodo/data/:data",
      element: <PeriodoContabiblisticoPagamentos/>,
    },
    {
      path: "/afiliados",
      element: <Afiliados/>,
    },
    {
      path: "/afliados-indetificacao",
      element: <AfiliadosIndentificacao/>,
    },
    {
      path: "/afliados-indetificacao/:id",
      element: <AfiliadosIdentidicadosGrupo/>,
    },
    {
      path: "/afiliado/:id",
      element: <EditarAfiliado/>,
    },
    {
      path: "/afiliado/permissoes/:id",
      element: <DefinirPermissoesAfiliados/>,
    },
    {
      path: "/webhooks",
      element: <Webhooks/>,
    },
    {
      path: "/webhooks-logs",
      element: <WebhooksLogs/>,
    },
    {
      path: "/webhooks/retry",
      element: <WebhooksRetry/>,
    },
    {
      path: "/endpoint/:id",
      element: <EditarEndpoint/>,
    },
    {
      path: "/servico-api",
      element: <ServicoAPI/>,
    },
    {
      path: "/notificacoes",
      element: <Notificacoes/>,
    },
    {
      path: "/docs",
      element: <Docs/>,
    },
    {
      path: "/faq",
      element: <FAQ/>,
    },
    {
      path: "/login",
      element: <Login/>,
    }, 
    {
      path: "/login/afiliado",
      element: <LoginAfiliado/>,
    }, 
    {
      path: "/mudar-senha-padrao",
      element: <MudarSenhaPadrao/>,
    },    
    {
      path: "/mudar-senha",
      element: <MudarSenha/>,
    },    
    {
      path: "/perdi-minha-senha",
      element: <PerdiMinhaSenha/>,
    },    
    {
      path: "/codigo-de-seguranca",
      element: <RedifinirSenha/>,
    },    
    {
      path: "/redifinir-senha",
      element: <TrocarSenhaSeguranca/>,
    },    
    {
      path: "/adm/",
      element: <AdmDashboard/>,
    },
    {
      path: "/adm/referencias/:id",
      element: <ReferenciasEntidades/>,
    },
    {
      path: "/adm/referencias/editar/:id",
      element: <EditarReferenciasAdm/>,
    },
    {
      path: "/adm/pagamentos/:id",
      element: <PagamentosEntidades/>,
    },
    {
      path: "/adm/pagamentos-nao-processados/:id",
      element: <PagamentosEntidadesNaoProcessados/>,
    },
    {
      path: "/adm/transacoes-gpo/:id",
      element: <TransacoesEntidadesGPO/>,
    },
    {
      path: "/adm/transacoes/:id",
      element: <TransacoesEntidades/>,
    },
    {
      path: "/adm/periodo-contabilistico/:id",
      element: <PeriodoContabiblisticoEntidades/>,
    },
    {
      path: "/adm/periodo-contabilistico/periodo/:periodo/data/:data/entidade/:id",
      element: <PeriodoContabiblisticoPagamentosEntidades/>,
    },
    {
      path: "/adm/relatorios/:id",
      element: <RelatoriosEntidades/>,
    },
    {
      path: "/adm/dashboard/:id",
      element: <DashboardEntidades/>,
    },
    {
      path: "/adm/ficheiros",
      element: <Ficheiros/>,
    },
    {
      path: "/adm/arquivos-fmovs",
      element: <FicheirosFMOVs/>,
    },
    {
      path: "/adm/entidades",
      element: <Entidades/>,
    },
    {
      path: "/adm/entidades/:id",
      element: <AdmEditarEntidades/>,
    },
    {
      path: "/adm/usuarios",
      element: <Usuarios/>,
    },
    {
      path: "/adm/usuarios/:id",
      element: <EditarUsuarios/>,
    },
    {
      path: "/adm/permissao/:id",
      element: <EditarPermissao/>,
    },
    {
      path: "/adm/permissoes",
      element: <Permissoes/>,
    },
    {
      path: "/adm/login",
      element: <AdmLogin/>,
    },
    {
      path: "/adm/logs",
      element: <Logs/>,
    },
    {
      path: "/adm/logs-requisicao",
      element: <LogsRequisicao/>,
    },
    {
      path: "/adm/conf-sms",
      element: <ConfSMS/>,
    },
    {
      path: "/adm/conf-sessao",
      element: <ConfSessao/>,
    },
    {
      path: "/adm/conf-pagamentos",
      element: <ConfPagamentos/>,
    },
    {
      path: "/adm/conf-integracao",
      element: <ConfIntegracao/>,
    },
    {
      path: "/adm/servicos",
      element: <ConfServicos/>,
    },
    {
      path: "/adm/afiliados",
      element: <AfiliadosAdm/>,
    },  
    {
      path: "/adm/afiliado/:id",
      element: <EditarAfiliadosAdm/>,
    },
    {
      path: "/adm/pos-configuracoes/:id",
      element: <POSComerciantes/>,
    },
    {
      path: "/adm/tokens/:id",
      element: <TokensEntidades/>,
    }, 
    {
      path: "/adm/webhooks",
      element: <WebhooksAdm/>,
    }, 
    {
      path: "/adm/webhooks-logs",
      element: <WebhooksLogsAdm/>,
    }, 
    {
      path: "/adm/webhooks-logs/:entidade",
      element: <WebhooksLogsAdmEntidade/>,
    }, 
    {
      path: "/adm/webhooks-logs/:entidade/falhas",
      element: <WebhooksLogsEntidadeFalhas/>,
    }, 
    {
      path: "/adm/webhooks/retry/:entidade",
      element: <WebhooksAdm/>,
    }, 
    {
      path: "/adm/definicoes/:id",
      element: <Definicoes/>,
    }, 
    {
      path: "/adm/endpoint/:id",
      element: <EditarEndpointAdm/>,
    },
    {
      path: "/adm/servicos/:id",
      element: <ConfEntidadeServicos/>,
    },
    {
      path: "/adm/email-marketing",
      element: <EmailMarket/>,
    },
    {
      path: "/adm/percentual",
      element: <Percentual/>,
    },
    {
      path: "/adm/custo-por-uso",
      element: <CustoUso/>,
    },
    {
      path: "/adm/custo-por-uso/:id",
      element: <CustoPorUsoEntidades/>,
    },
    {
      path: "/adm/sms-marketing",
      element: <SMSMarket/>,
    },
    {
      path: "/adm/whatsapp-marketing",
      element: <WhatsappMarket/>,
    },
    {
      path: "/adm/*",
      element: <Pagina404ADM/>,
    },
    {
      path: "/*",
      element: <Pagina404/>,
    },
    {
      path: "/sem-comunicacao",
      element: <ErroComunicacao/>, 
    },
  ]);
  
  export default router