import { Flex, Box, Center, Text, Button, useToast,
  PinInput, PinInputField, HStack, Image } from '@chakra-ui/react'
import AXIOS from '../Config/axiosConfig'
import { useEffect, useState } from 'react'
import {useFormik} from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import CONFIG from '../Config'
import LOGO from '../Assets/images/logo/logoi.png'
import { useTranslation } from 'react-i18next'

function Login() {

    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const ENTITY = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.SECURITY_CODE_ENTITY)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.SECURITY_CODE_ENTITY)).split(CONFIG.COOKIE_SESSION.SECURITY_CODE_ENTITY)[1] : ""
    const [loader, setLoader] = useState(false)
    const [texto, setTexto] = useState({bg:"error", texto:"Senha fraca"})
    const navigate =  useNavigate()
    const toast = useToast()
    const [user, setUser] = useState('1')
    const [userTime, setUserTime] = useState('10:00')
    const [userCod, setUserCod] = useState({texto1:'',texto2:'',texto3:'',texto4:''})
    const {t, i18n} = useTranslation()
    
    
    const clearLogout = function(){
      try {
        document.cookie = CONFIG.COOKIE_SESSION.SECURITY_CODE_ENTITY + '=; Max-Age=0'
        navigate('/redifinir-senha')
      }catch(e) {
        toast({
          title: t('Alerta'),
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: 'error',
        })
        }
    }
    
    const formik = useFormik({
      initialValues:{
        codigo_seguranca:'',
        entidade:''
      },
      onSubmit: async (values)=> {        
        try {
        values.codigo_seguranca = `${userCod.texto1}${userCod.texto2}${userCod.texto3}${userCod.texto4}` 
        if(values.codigo_seguranca){
          
              
          
          
          if(ENTITY){
          
            values.entidade = ENTITY.split(':')[0]
            
          }else{
            toast({
              title: t('Alerta'),
              description: t("Entidade invalida. Por favor segue os passos correctos para redifinição de senha"),
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
            
            return;
          }
            
            const res =  await AXIOS.post(`/clientes/codigo-seguranca/redifinir`,values)
            console.log(res.data)
            
            if(res.data.status === "sucesso"){
            
              toast({
                title: t('Verificação de usuário'),
                description: t(res.data.mensagem),
                status: 'success',
                duration: 5000,
                isClosable: true,
              })
              
              document.cookie = CONFIG.COOKIE_SESSION.ID_CODE_ENTITY + res.data.info.codigo_entidade + ';path=/';    
              
              setTimeout(() => {
                clearLogout()
              }, 3000)
              
              }else{
                toast({
                  title: t('Verificação de usuário'),
                  description:  t(res.data.mensagem),
                  status: 'error',
                  duration: 4000,
                  isClosable: true,
                })
              }         
                    
            }else{
              toast({
                title: t('Aviso'),
                description:  t("Campo do codigo vazio ou expirado!"),
                status: 'error',
                duration: 4000,
                isClosable: true,
              })
            }
        
          }catch (error) {
            toast({
              title: t('Alerta'),
              description: t("Não conseguimos ter comunicação! Verifique sua internet"),
              status: 'error',
            })
        }
        
      },

    })
    
    useEffect(()=>{
    
      if(loginValue){
        const value = JSON.parse(loginValue)
          if(value?.mensagem?.numero_entidade){
             navigate('/')
          }
          else if(!ENTITY){
            navigate('/login')
          }
        }
        
    },[])
    
    const tempoRestante = () => {
        setInterval (() => {
          const dateInicio = new Date().getTime();
          const dateFinal = new Date(Number(ENTITY.split(':')[1])).getTime();  
              
          const tempoRestante = (dateFinal - dateInicio)
          const dias = Math.floor(tempoRestante / 86400000)
          const horas = Math.floor((tempoRestante / 3600000 ) % 24);
          const minutos = Math.floor((tempoRestante / 60000 ) % 60);
          const segundos = Math.floor((tempoRestante / 1000 ) % 60);
          if(minutos < 0)
            setUserTime(`0:0`)
          else
            setUserTime(`${minutos}:${segundos}`)
        },1000)
              
        return userTime
    }
    
  return (
    <Flex flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" style={{minHeight:'100vh'}} direction="column">
        <Box w="50%" bg="white" h="100%">
          <Center>
              <form onSubmit={formik.handleSubmit}>
              <Box color="gray.500" bg='transparent' p={10}>
                  <Center mb={1}><Image src={LOGO} width={200} /></Center>
                  <Text fontSize={40} fontWeight={700} color="blue.500" textAlign="center" >{t("Introduza o codigo de segurança")}</Text>
                  <Text mb={5} p={5} textAlign="center">
                    {t("Caro(a) Cliente, introduza o codigo de segurança recebido por um dos nossos canais na sequencia correcta")}
                  </Text>
                  
                  <Flex direction="column" alignItems="center">
                    <Text fontSize={18} color="green.500"  mb={1}>{t("Restante")}: {tempoRestante() == "0:0" ? <b style={{color:"red"}}>{tempoRestante()}</b> : <b>{tempoRestante()}</b>}</Text>
                    <HStack>
                      <PinInput size='lg'  errorBorderColor = "red.500" name='codigo_seguranca'>
                        <PinInputField required onChange={(evt) => setUserCod(prev => ({...prev, texto1:evt.target.value}) ) } />
                        <PinInputField required onChange={(evt) => setUserCod(prev => ({...prev, texto2:evt.target.value}) ) } />
                        <PinInputField required onChange={(evt) => setUserCod(prev => ({...prev, texto3:evt.target.value}) ) }  />
                        <PinInputField required onChange={(evt) => setUserCod(prev => ({...prev, texto4:evt.target.value}) ) }  />
                      </PinInput>
                    </HStack>
                      
                    <Button bg='blue.400' rounded={0} w="30%" _hover={{backgroundColor:"blue.600"}} color="white" type='submit' mt={5} mb={5} p={6}>{t("Verificar")}</Button>
                    <Link to="/login" color='blue.500'>Login</Link>
                    <Text mt={10}>{t("Enviamos um E-mail ou mensagem pelo seu Whatsapp com o codigo se segurança para verificação, que deverá ser inserida acima")}</Text>
                  </Flex>
                  <Flex mt={10} justifyContent="center" color="blue.500">
                    <ul>
                      <li onClick={()=> i18n.changeLanguage('pt')}  style={{cursor:"pointer"}}>{t("Português")}</li>
                      <li onClick={()=> i18n.changeLanguage('fr')}  style={{cursor:"pointer"}}>{t("Françês")}</li>
                      <li onClick={()=> i18n.changeLanguage('en')}  style={{cursor:"pointer"}}>{t("Inglês")}</li>
                      <li onClick={()=> i18n.changeLanguage('es')}  style={{cursor:"pointer"}}>{t("Espanhol")}</li>
                      <li onClick={()=> i18n.changeLanguage('ar')}  style={{cursor:"pointer"}}>{t("Ârabe")}</li>
                      <li onClick={()=> i18n.changeLanguage('it')}  style={{cursor:"pointer"}}>{t("Italiano")}</li>
                    </ul>
                  </Flex>
              </Box> 
              </form>
          </Center>
        </Box>
        <Box>
              <Text mt={70}><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
              <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
          </Box>
    </Flex>
  )
}

export default Login